import { useEffect, useState, useCallback, useMemo } from "react"
import { debounce } from "utils/common"
import { useRouter, usePathname } from "next/navigation"
import { searchCompanies, getTop10Companies } from "api/companies"
import { Modal, Form, Spinner } from "react-bootstrap"

import SearchModalPublishedList from "./publishedList"
import SearchModalUnpublishedList from "./unpublishedList"
import SearchModalEmptyMessage from "./emptyMessage"

import Close from "icons/close.svg"
import Search from "icons/search.svg"

import styles from "./search.module.scss"

const SearchModal = ({ show, handleClose, location, flag }) => {
  const router = useRouter()
  const pathname = usePathname()
  const [searchTerm, setSearchTerm] = useState("")
  const [results, setResults] = useState([])
  const [published, setPublished] = useState(false)
  const [total, setTotal] = useState("")
  const [cursor, setCursor] = useState(0)
  const [firstLoaded, setFirstLoaded] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (show) {
      fetchTop10Companies()
    }
  }, [show])

  const closeAndReset = useCallback(() => {
    handleClose()
    setSearchTerm("")
    setFirstLoaded(true)
  }, [handleClose])

  useEffect(() => {
    closeAndReset()
  }, [pathname])

  const fetchCompanies = useCallback(term => {
    if (term.length > 0) {
      searchCompanies(term).then(({ data }) => {
        setResults(data.companies)

        setPublished(data?.meta?.published)
        setFirstLoaded(false)
      })
    } else {
      fetchTop10Companies()
    }
  }, [])

  const fetchTop10Companies = () => {
    getTop10Companies()
      .then(res => {
        setFirstLoaded(true)
        setResults(res?.data?.companies)
        setTotal(res?.data?.meta?.total_count)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const debounceHandleChange = useMemo(
    () => debounce(fetchCompanies, 200),
    [fetchCompanies]
  )

  const handleChange = event => {
    const term = event.target.value
    setSearchTerm(term)
    debounceHandleChange(term)
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      if (!published) return
      router.push(`/companies/${results[cursor].slug}`)
    } else if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1)
    } else if (e.keyCode === 40 && cursor < results.length - 1) {
      setCursor(cursor + 1)
    }
  }

  return (
    <Modal show={show} onHide={closeAndReset} className={styles.modal}>
      <Modal.Header>
        <div className={styles.header}>
          <Search height="22" width="22" />
          <Form.Control
            className={styles.input}
            type="text"
            placeholder={`Search ${Number(total).toLocaleString()} companies`}
            value={searchTerm}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <Close
            height="30"
            width="30"
            onClick={closeAndReset}
            className={styles.closeButton}
          />
        </div>
      </Modal.Header>
      {loading ? (
        <div className={styles.loadingWrapper}>
          <Spinner animation="border" variant="dark" />
        </div>
      ) : (
        <Modal.Body>
          {firstLoaded && (
            <div className={styles.topRatedTitle}>Top Rated Companies</div>
          )}
          {results.length === 0 && <SearchModalEmptyMessage />}

          {!firstLoaded && !published && results.length > 0 && (
            <SearchModalUnpublishedList
              results={results}
              cursor={cursor}
              searchTerm={searchTerm}
            />
          )}

          {(firstLoaded || published) && (
            <SearchModalPublishedList
              results={results}
              cursor={cursor}
              searchTerm={searchTerm}
              location={location}
              flag={flag}
            />
          )}
        </Modal.Body>
      )}
    </Modal>
  )
}

export default SearchModal

import { trackAction } from "utils/analytics"
import { escapeRegex } from "utils/string"

import Image from "next/image"
import Link from "next/link"

import Check from "icons/check.svg"

import styles from "./publishedList.module.scss"

const SearchModalPublishedList = ({
  results,
  cursor,
  searchTerm,
  location,
  flag,
}) => {
  const buildResults = () => {
    const displayedResults = [...results]
    if (results.length === 1) {
      const resultCopy1 = { ...displayedResults[0] }
      resultCopy1.subPage = "salaries"
      const resultCopy2 = { ...displayedResults[0] }
      resultCopy2.subPage = "reviews"
      const resultCopy3 = { ...displayedResults[0] }
      resultCopy3.subPage = "jobs"

      const isPartial = resultCopy1?.published_status === "partial"

      if (resultCopy1?.has_active_jobs) {
        if (resultCopy1?.has_reviews && !isPartial) {
          displayedResults.push(resultCopy1, resultCopy2, resultCopy3)
        } else {
          displayedResults.push(resultCopy1, resultCopy3)
        }
      } else {
        if (resultCopy1?.has_reviews && !isPartial) {
          displayedResults.push(resultCopy1, resultCopy2)
        } else {
          displayedResults.push(resultCopy1)
        }
      }
    }

    return displayedResults
  }

  return (
    <section>
      {buildResults().map((company, i) => {
        const {
          id,
          name,
          logo,
          industry,
          repvue_score,
          slug,
          has_active_jobs,
          subPage,
          published_status,
        } = company

        const regexSearchTerm = escapeRegex(searchTerm)
        const nameResults = name.replace(
          new RegExp(regexSearchTerm, "gi"),
          match => `<span>${match}</span>`
        )

        const isPartial = published_status === "partial"

        return (
          <Link
            href={
              subPage ? `/companies/${slug}/${subPage}` : `/companies/${slug}`
            }
            key={subPage ? `${id}${subPage}` : id}
            className={`${styles.row} ${
              cursor === i ? styles.activeRow : null
            }`}
            onClick={() => {
              trackAction("Company Clicked", {
                company_name: name,
                location,
                experiment_group_search: localStorage.experiment_group_search,
              })
            }}
          >
            {logo && (
              <div className={styles.logo}>
                <Image
                  alt={name}
                  src={logo}
                  quality={80}
                  height={48}
                  width={48}
                />
              </div>
            )}
            <div className={styles.text}>
              <div
                className={styles.name}
                dangerouslySetInnerHTML={{
                  __html: subPage
                    ? `${nameResults} ${
                        subPage.charAt(0).toUpperCase() + subPage.slice(1)
                      }`
                    : nameResults,
                }}
              ></div>
              {!subPage && <div className={styles.type}>{industry}</div>}
            </div>
            {has_active_jobs && !subPage && (
              <div className={styles.hiring}>
                <Check height="10" width="12" /> Hiring
              </div>
            )}
            {!subPage && !isPartial && (
              <div className={`${styles.score} ${styles.blue}`}>
                {repvue_score}
              </div>
            )}
          </Link>
        )
      })}
    </section>
  )
}

export default SearchModalPublishedList

import apiCall from "utils/api"
import { addToWatchList, removeFromWatchList } from "reducers/auth"

export const getCompanies = params => {
  const options = { params }
  return apiCall("get", `companies`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompany = slug => {
  return apiCall("get", `companies/${slug}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getOverview = slug => {
  return apiCall("get", `/companies/${slug}/overview`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getReviews = (slug, params) => {
  const options = { params }
  options.per_page = 20
  return apiCall("get", `/companies/${slug}/reviews`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyBenefits = slug => {
  return apiCall("get", `/companies/${slug}/benefits`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getTechStacks = slug => {
  return apiCall("get", `/companies/${slug}/tech_stacks`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const searchCompanies = input => {
  return apiCall("get", `companies/quick_search?search=${input}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const searchCompaniesPublished = input => {
  return apiCall("get", `companies/quick_published_search?search=${input}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getTop10Companies = () => {
  return apiCall("get", `companies?per_page=10`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompaniesFilterOptions = () => {
  return apiCall("get", `/companies/form_options`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const filterCompanies = filters => {
  return apiCall("get", `companies?${filters}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const searchCompaniesRating = search => {
  return apiCall("get", `companies/rating_creation_search?search=${search}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const suggestTechStacks = (stacks, slug) => {
  const options = {
    data: stacks,
  }
  return apiCall("post", `companies/${slug}/tech_stacks/suggest`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const followCompany = company => async dispatch => {
  return apiCall("post", `/companies/${company.slug}/follows`)
    .then(response => {
      dispatch(addToWatchList(company))
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const unfollowCompany = (slug, name) => async dispatch => {
  return apiCall("delete", `/companies/${slug}/follows`)
    .then(response => {
      dispatch(removeFromWatchList(name))
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const findCompanyWebsite = website => {
  return apiCall("get", `companies/find_by_website?website=${website}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompareCompany = slug => {
  return apiCall("get", `companies/${slug}/compare`)
}

export const checkFormerEmployee = slug => {
  return apiCall("get", `companies/${slug}/check_former_employee`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyJobs = (slug, params) => {
  const options = { params }
  return apiCall("get", `companies/${slug}/jobs`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyQuestions = (slug, params) => {
  const options = { params }
  return apiCall("get", `companies/${slug}/questions`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanySalaryCountries = slug => {
  return apiCall("get", `companies/${slug}/salaries/countries`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getInternationalCompanySalary = (slug, code) => {
  return apiCall("get", `companies/${slug}/salaries?country_code=${code}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getBusinessUnits = slug => {
  return apiCall("get", `companies/${slug}/business_units`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyReviews = (slug, params) => {
  const options = { params }
  return apiCall("get", `companies/${slug}/reviews`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

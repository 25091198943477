import Link from "next/link"
import styles from "./emptyMessage.module.scss"

const SearchModalEmptyMessage = () => (
  <div className={styles.noCompaniesWrapper}>
    <div className={styles.noCompaniesTitle}>
      No companies matched that search term
    </div>
    <div className={styles.noCompaniesDesc}>
      Are we missing a company that you&apos;ve worked&nbsp;for?
      <br />
      <br />
      <Link href="/rating/step1">Add a rating</Link> for the missing company.
      Once the company has at least 7 ratings, it can be&nbsp;published!
    </div>
  </div>
)

export default SearchModalEmptyMessage

import { useState } from "react"
import pluralize from "pluralize"
import cn from "classnames"
import { escapeRegex } from "utils/string"

import Link from "next/link"
import Place from "icons/place.svg"
import Button from "components/shared/Button"
import styles from "./unpublishedList.module.scss"

const SearchModalUnpublishedList = ({ results, cursor, searchTerm }) => {
  const RATINGS = 6
  const MIN_RATINGS = 2
  const [rowSelect, setRowSelect] = useState(null)

  return (
    <section>
      <article className={styles.unpublishedList__header}>
        <span>No published companies match that search term</span>
      </article>

      <article className={styles.unpublishedList__title}>
        <h6>Rate an Unpublished Company</h6>
      </article>

      {results?.map((company, i) => {
        const { id, name, slug, ratings_count } = company
        const regexSearchTerm = escapeRegex(searchTerm)
        const nameResults = name.replace(
          new RegExp(regexSearchTerm, "gi"),
          match => `<span>${match}</span>`
        )

        return (
          <article
            className={cn(styles.unpublishedList__row, {
              [styles.unpublishedList__activeRow]: cursor === i,
            })}
            onMouseEnter={() => setRowSelect(i)}
            onMouseLeave={() => setRowSelect(null)}
            key={id}
          >
            <div className={styles.unpublishedList__icon}>
              <Place height="28" width="28" />
            </div>

            <div className={styles.unpublishedList__text}>
              <div
                className={styles.unpublishedList__name}
                dangerouslySetInnerHTML={{
                  __html: nameResults,
                }}
              ></div>
              <div className={styles.unpublishedList__ratings}>
                {ratings_count < RATINGS &&
                  RATINGS - ratings_count <= MIN_RATINGS && (
                    <>
                      <span className={styles.unpublishedList__ratings__count}>
                        {RATINGS - ratings_count} more{" "}
                        {pluralize("rating", RATINGS - ratings_count, false)}{" "}
                      </span>
                      <span> needed to publish</span>
                    </>
                  )}

                {RATINGS - ratings_count > MIN_RATINGS && (
                  <span>More ratings needed to publish</span>
                )}

                {ratings_count >= RATINGS && (
                  <span>Company publication pending. Add a rating!</span>
                )}
              </div>
            </div>

            <div
              className={cn(styles.unpublishedList__actions, {
                [styles.unpublishedList__actions__show]: rowSelect === i,
              })}
            >
              <Link href={`/rating/step1?company=${slug}`}>
                <Button variant="primary">Add a Rating</Button>
              </Link>
            </div>
          </article>
        )
      })}
    </section>
  )
}

export default SearchModalUnpublishedList
